<template>
  <div>
    <div v-if="loading" class="text-center">
      <a-spin tip="Loading..." />
    </div>
    <a-form-model
      v-if="!loading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item ref="status" label="ステータス" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio :value="0">無効</a-radio>
          <a-radio :value="1">有効</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item ref="time" label="時間" prop="time">
        <a-time-picker
          v-model="form.time"
          format="HH:mm"
          :minute-step="5"
          @blur="
            () => {
              $refs.time.onFieldBlur()
            }
          "
        />
      </a-form-model-item>

      <a-form-model-item ref="handle_agent_company_array" label="対応会社" prop="handle_agent_company_array">
        <a-checkbox-group
          v-model="form.handle_agent_company_array"
        >
          <a-col :xs="24" v-for="item in handledCompanies" :key="item.id">
            <a-checkbox :key="item.id" :value="item.id">{{ item.name }}</a-checkbox>
          </a-col>
        </a-checkbox-group>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          {{ submitString }}
        </a-button>
        <router-link :to="{ name: 'companyGroupShow', params: { id: companyGroupId } }">
          <a-button style="margin-left: 10px;">
            戻る
          </a-button>
        </router-link>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      loading: true,
      companyGroupId: this.$route.params.id,
      handleRollCallAgentCompanyloading: true,
      handledCompanies: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        status: 0,
        time: '',
        handle_agent_company_array: [],
      },
      rules: {
        status: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        time: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
        handle_agent_company_array: [
          { required: true, message: '必須項目です。', trigger: 'blur' },
        ],
      },
    }
  },
  props: [
    'submitType',
    'submitString',
  ],
  created() {
    const handleCompanyResult = Vue.prototype.$api.send('get', 'master/handled_companies')
    handleCompanyResult.then(response => {
      this.handledCompanies = response
      this.handleRollCallAgentCompanyloading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 点呼代行受付会社一覧の取得に失敗しました。',
        })
        this.loading = false
      })

    if (this.submitType === 'put') {
      const result = Vue.prototype.$api.send('get', 'company_groups_timers/' + this.$route.params.id)
      result.then(response => {
        this.loading = false
        this.companyGroupId = response.company_group_id
        this.form.status = response.status
        this.form.time = moment(response.time, 'HH:mm')
        this.form.handle_agent_company_array = response.handle_agent_company_array.split(',').map(Number)
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 会社グループの取得に失敗しました。',
          })
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  methods: {
    onSubmit() {
      const _this = this
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_this.submitType === 'post') {
            const send = Vue.prototype.$api.send('post', 'company_groups/' + _this.$route.params.id + '/create_timer', this.form)
            send.then(response => {
              _this.$notification['success']({
                message: 'タイマーを作成しました。',
              })
              _this.$router.push({ name: 'companyGroupShow', params: { id: _this.companyGroupId } })
            })
              .catch(error => {
                if (error.status === 403) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          } else if (_this.submitType === 'put') {
            const send = Vue.prototype.$api.send('put', 'company_groups/timers/' + _this.$route.params.id, this.form)
            send.then(response => {
              _this.$notification['success']({
                message: 'タイマーを更新しました。',
              })
              _this.$router.push({ name: 'companyGroupShow', params: { id: _this.companyGroupId } })
            })
              .catch(error => {
                if (error.status === 403 || error.status === 400) {
                  _this.$notification['error']({
                    message: error.data.data,
                  })
                } else {
                  for (var key in error.data.errors) {
                    _this.$refs[key].validateState = 'error'
                    _this.$refs[key].validateMessage = error.data.errors[key]
                  }
                }
                console.log(error)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
