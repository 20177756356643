var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol
              }
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "status",
                  attrs: { label: "ステータス", prop: "status" }
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("a-radio", { attrs: { value: 0 } }, [_vm._v("無効")]),
                      _c("a-radio", { attrs: { value: 1 } }, [_vm._v("有効")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "time", attrs: { label: "時間", prop: "time" } },
                [
                  _c("a-time-picker", {
                    attrs: { format: "HH:mm", "minute-step": 5 },
                    on: {
                      blur: function() {
                        _vm.$refs.time.onFieldBlur()
                      }
                    },
                    model: {
                      value: _vm.form.time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "time", $$v)
                      },
                      expression: "form.time"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "handle_agent_company_array",
                  attrs: {
                    label: "対応会社",
                    prop: "handle_agent_company_array"
                  }
                },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      model: {
                        value: _vm.form.handle_agent_company_array,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "handle_agent_company_array", $$v)
                        },
                        expression: "form.handle_agent_company_array"
                      }
                    },
                    _vm._l(_vm.handledCompanies, function(item) {
                      return _c(
                        "a-col",
                        { key: item.id, attrs: { xs: 24 } },
                        [
                          _c(
                            "a-checkbox",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.submitString) + "\n      "
                      )
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "companyGroupShow",
                          params: { id: _vm.companyGroupId }
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticStyle: { "margin-left": "10px" } },
                        [_vm._v("\n          戻る\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }